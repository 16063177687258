.container {
  background-color: rgb(255, 255, 255);
  display: flex;

  flex-direction: column;
  align-items: center;

  letter-spacing: 2px;
}
.container h3,
h4 {
  margin-bottom: 0;
}

.symbol {
  color: red;
}
.linkedIn {
  background-color: none;
  border-radius: 1rem;
  height: 1.5rem;
}
.github {
  border-radius: 1rem;
  background-color: none;
  height: 1.5rem;
}
.github:hover,
.github:active {
  cursor: pointer;
  animation: links 1000ms linear forwards;
}
.linkedIn:hover,
.linkedIn:active {
  cursor: pointer;
  animation: links 1000ms linear forwards;
}
@keyframes links {
  0% {
  }
  100% {
    border-radius: 1rem;
    background-color: red;
  }
}
