.container {
  color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding-top: 5rem;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
}
.container ul {
  padding: 0;
  font-size: large;
  list-style: none;
}
.footer {
  height: 5rem;
}
