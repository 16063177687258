.container {
  padding: 0;

  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 1rem;

  border-radius: 1px;
}
.container li {
  font-size: 150%;
}
.container li a {
}
.pageNumber {
  border-bottom: solid white 1px;
}

.pageItem {
  font-size: 75%;
  color: red;

  width: 5rem;
}
.pageItem a {
  font-size: 75%;
}
.navbar {
  -webkit-box-shadow: 0px 1px 1px white;
  -moz-box-shadow: 0px 1px 1px white;
  box-shadow: 0px 1px 1px white;
  border-radius: 5rem;
  width: 20rem;
}
