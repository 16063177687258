.container {
  list-style: none;
  width: 18.7rem;
  padding: 1rem 0 1rem 0;
}
.innerContainer {
  margin-top: 20px;
  display: flex;
  border-top: 1px solid red;
  justify-content: space-around;
  border-radius: 5rem;
}
.innerContainer ul {
}
.git {
  background-color: white;
  border-radius: 1rem;
}
.git:hover,
.git:active {
  background-color: red;
}
.link {
  border: 1px solid white;

  padding: 1px;
  border-radius: 5px;
}
.link:hover,
.link:active {
  color: red;
  border-color: red;
}
.links li {
  padding-bottom: 0.5rem;
}

.title {
  text-align: center;
  border-bottom: red solid 5px;
}
.footer {
  height: 5rem;
}
