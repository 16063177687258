.background {
  text-align: center;
  background-color: transparent;

  height: 100vh;
  color: white;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  align-items: center;
  margin-top: 10rem;
}
.title {
  letter-spacing: 1.5px;
}
.subTitle {
  font-family: 'Courier New', Courier, monospace;
}

.logo {
  height: 10rem;
  width: 10rem;

  position: relative;
}
.lastName {
  color: red;
}
.summary {
  font-family: 'Courier New', Courier, monospace;
}
.background h3 {
  letter-spacing: 1px;
  margin-top: 5rem;
}
