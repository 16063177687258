.container {
  display: none;
  color: white;
  height: 2rem;
  padding: 5px;
  background-color: rgba(255, 0, 0, 0.737);
  box-shadow: rgba(255, 254, 254, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.links_container {
  position: absolute;
  right: 5rem;
}
.container ul {
  position: absolute;
  display: flex;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

  gap: 1rem;
  list-style: none;
}
.container ul li:hover {
  cursor: pointer;
  animation: nav 200ms linear forwards;
}
.linkedIn {
  background-color: none;
  border-radius: 1rem;
  height: 2rem;
}
.github {
  border-radius: 1rem;
  background-color: none;
}
.github:hover,
.github:active {
  cursor: pointer;
  animation: links 1000ms linear forwards;
}
.linkedIn:hover,
.linkedIn:active {
  cursor: pointer;
  animation: links 1000ms linear forwards;
}
.linkContainer {
  padding-top: 5rem;
  display: flex;
  gap: 1rem;
}
@media only screen and (min-width: 800px) {
  .container {
    display: inherit;
  }
}
@keyframes nav {
  0% {
    color: white;
    padding-top: 0;
    border-radius: 5px;
  }
  100% {
    color: black;
    padding-top: 5px;
  }
}
@keyframes links {
  0% {
  }
  100% {
    border-radius: 1rem;
    background-color: rgb(255, 255, 255);
  }
}
