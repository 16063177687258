.container {
  background-color: black;
  position: absolute;
  color: white;
}
.icon {
  background-color: red;
  width: 40px;
  height: 5px;
  top: 40px;
  left: 20px;
  position: absolute;
  z-index: 100;
}
.icon:hover {
  cursor: pointer;
}
.icon:before {
  content: '';
  background-color: white;
  width: 40px;
  height: 5px;
  top: 12px;

  position: absolute;
  animation: 0.5s ease-in 1 menuDropIn2;
}
.icon:after {
  content: '';
  background-color: white;
  width: 40px;
  height: 5px;
  top: -12px;

  position: absolute;
  animation: 0.5s ease-in 1 menuDropIn;
}
#menuInput {
  display: none;
}
/* Hamber Icon Animation */
#menuInput:checked ~ .icon:after {
  transform: rotate(60deg);
  top: 0;
  transition-duration: 1s;
}
#menuInput:checked ~ .icon:before {
  transform: rotate(-120deg);
  top: 0;
  transition-duration: 1s;
}
#menuInput:checked ~ .icon {
  transform: rotate(420deg);

  transition-duration: 1s;
}
#menuInput:not(:checked) ~ .icon:after {
  transform: rotate(0deg);
  top: -12px;
  transition-duration: 1s;
}
#menuInput:not(:checked) ~ .icon:before {
  transform: rotate(0deg);
  top: 12px;
  transition-duration: 1s;
}
#menuInput:not(:checked) ~ .icon {
  transform: rotate(0deg);

  transition-duration: 1s;
}
/* Hamber Icon Animation End */

/*Menu Modal Animation */
#menuInput:checked ~ .modal {
  transform: translateX(250px);

  transition-duration: 1s;
}
#menuInput:not(:checked) ~ .modal {
  transform: translateX(0);

  transition-duration: 1s;
}

.modal {
  background-color: black;
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  z-index: 50;
  right: 0;
}

/*Menu*/
.menu ul {
  position: absolute;
  top: 100px;
  right: 0;
  list-style: none;
  z-index: 60;
}
.menu ul li {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  font-size: x-large;
}
.menu ul li:hover a,
.menu ul li:active a {
  color: red;
}
#menuInput:checked ~ .menu ul {
  transform: translateX(220px);

  transition-duration: 1s;
}
#menuInput:not(:checked) ~ .menu ul {
  transform: translateX(0);

  transition-duration: 1s;
}

/* links */
.linkedIn {
  background-color: white;
  border-radius: 1rem;
  height: 2rem;
}
.github {
  border-radius: 1rem;
  background-color: white;
}
.github:hover,
.github:active {
  background-color: red;
}
.linkedIn:hover,
.linkedIn:active {
  background-color: red;
}
.linkContainer {
  padding-top: 5rem;
  display: flex;
  gap: 1rem;
}

@keyframes menuDropIn {
  0% {
    transform: translateY(12px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes menuDropIn2 {
  0% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateX(0);
  }
}
@media only screen and (min-width: 800px) {
  .container {
    display: inherit;
  }
}
@media only screen and (min-width: 800px) {
  .container {
    display: none;
  }
}
